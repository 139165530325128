import type { HeroType } from '../../Hero';

export const heroBackgroundFallback = (heroType: HeroType) => {
  if (heroType === 'DiscoverWeeklyHero') {
    return {
      image: {
        url: '/static/images/hero-backgrounds/mycasino-discover-weekly.jpg',
      },
      mobileImage: {
        url: '/static/images/hero-backgrounds/mycasino-discover-weekly-mobile.jpg',
      },
    };
  }

  if (heroType === 'GameItemHero') {
    return {
      image: {
        url: '/static/images/hero-backgrounds/mycasino-game-item.jpg',
      },
      mobileImage: {
        url: '/static/images/hero-backgrounds/mycasino-game-item-mobile.jpg',
      },
    };
  }

  return {
    image: {
      url: '/static/images/hero-backgrounds/mycasino-basic.jpg',
    },
    mobileImage: {
      url: '/static/images/hero-backgrounds/mycasino-basic-mobile.jpg',
    },
  };
};
